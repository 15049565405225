exports.components = {
  "component---src-components-organisms-news-index-tsx": () => import("./../../../src/components/organisms/News/index.tsx" /* webpackChunkName: "component---src-components-organisms-news-index-tsx" */),
  "component---src-components-organisms-post-index-tsx": () => import("./../../../src/components/organisms/Post/index.tsx" /* webpackChunkName: "component---src-components-organisms-post-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-finances-tsx": () => import("./../../../src/pages/app-finances.tsx" /* webpackChunkName: "component---src-pages-app-finances-tsx" */),
  "component---src-pages-app-frases-tsx": () => import("./../../../src/pages/app-frases.tsx" /* webpackChunkName: "component---src-pages-app-frases-tsx" */),
  "component---src-pages-apps-delete-account-tsx": () => import("./../../../src/pages/apps/delete-account.tsx" /* webpackChunkName: "component---src-pages-apps-delete-account-tsx" */),
  "component---src-pages-apps-financas-index-tsx": () => import("./../../../src/pages/apps/finanças/index.tsx" /* webpackChunkName: "component---src-pages-apps-financas-index-tsx" */),
  "component---src-pages-apps-finances-index-tsx": () => import("./../../../src/pages/apps/finances/index.tsx" /* webpackChunkName: "component---src-pages-apps-finances-index-tsx" */),
  "component---src-pages-apps-finances-plataforma-web-tsx": () => import("./../../../src/pages/apps/finances/plataforma-web.tsx" /* webpackChunkName: "component---src-pages-apps-finances-plataforma-web-tsx" */),
  "component---src-pages-apps-finances-premium-tsx": () => import("./../../../src/pages/apps/finances/premium.tsx" /* webpackChunkName: "component---src-pages-apps-finances-premium-tsx" */),
  "component---src-pages-apps-finances-terms-tsx": () => import("./../../../src/pages/apps/finances/terms.tsx" /* webpackChunkName: "component---src-pages-apps-finances-terms-tsx" */),
  "component---src-pages-apps-frases-index-tsx": () => import("./../../../src/pages/apps/frases/index.tsx" /* webpackChunkName: "component---src-pages-apps-frases-index-tsx" */),
  "component---src-pages-apps-index-tsx": () => import("./../../../src/pages/apps/index.tsx" /* webpackChunkName: "component---src-pages-apps-index-tsx" */),
  "component---src-pages-apps-phrases-index-tsx": () => import("./../../../src/pages/apps/phrases/index.tsx" /* webpackChunkName: "component---src-pages-apps-phrases-index-tsx" */),
  "component---src-pages-apps-phrases-terms-tsx": () => import("./../../../src/pages/apps/phrases/terms.tsx" /* webpackChunkName: "component---src-pages-apps-phrases-terms-tsx" */),
  "component---src-pages-apps-productivity-index-tsx": () => import("./../../../src/pages/apps/productivity/index.tsx" /* webpackChunkName: "component---src-pages-apps-productivity-index-tsx" */),
  "component---src-pages-apps-productivity-premium-tsx": () => import("./../../../src/pages/apps/productivity/premium.tsx" /* webpackChunkName: "component---src-pages-apps-productivity-premium-tsx" */),
  "component---src-pages-apps-productivity-promotion-tsx": () => import("./../../../src/pages/apps/productivity/promotion.tsx" /* webpackChunkName: "component---src-pages-apps-productivity-promotion-tsx" */),
  "component---src-pages-apps-productivity-terms-tsx": () => import("./../../../src/pages/apps/productivity/terms.tsx" /* webpackChunkName: "component---src-pages-apps-productivity-terms-tsx" */),
  "component---src-pages-apps-produtividade-index-tsx": () => import("./../../../src/pages/apps/produtividade/index.tsx" /* webpackChunkName: "component---src-pages-apps-produtividade-index-tsx" */),
  "component---src-pages-black-friday-compra-individual-tsx": () => import("./../../../src/pages/black-friday-compra-individual.tsx" /* webpackChunkName: "component---src-pages-black-friday-compra-individual-tsx" */),
  "component---src-pages-black-friday-tsx": () => import("./../../../src/pages/black-friday.tsx" /* webpackChunkName: "component---src-pages-black-friday-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bonus-tsx": () => import("./../../../src/pages/bonus.tsx" /* webpackChunkName: "component---src-pages-bonus-tsx" */),
  "component---src-pages-canecas-tsx": () => import("./../../../src/pages/canecas.tsx" /* webpackChunkName: "component---src-pages-canecas-tsx" */),
  "component---src-pages-combo-app-curso-habito-tsx": () => import("./../../../src/pages/combo-app-curso-habito.tsx" /* webpackChunkName: "component---src-pages-combo-app-curso-habito-tsx" */),
  "component---src-pages-combo-app-curso-produtividade-tsx": () => import("./../../../src/pages/combo-app-curso-produtividade.tsx" /* webpackChunkName: "component---src-pages-combo-app-curso-produtividade-tsx" */),
  "component---src-pages-combo-app-cursos-tsx": () => import("./../../../src/pages/combo-app-cursos.tsx" /* webpackChunkName: "component---src-pages-combo-app-cursos-tsx" */),
  "component---src-pages-combo-app-ebook-tsx": () => import("./../../../src/pages/combo-app-ebook.tsx" /* webpackChunkName: "component---src-pages-combo-app-ebook-tsx" */),
  "component---src-pages-combo-apps-ebooks-cursos-tsx": () => import("./../../../src/pages/combo-apps-ebooks-cursos.tsx" /* webpackChunkName: "component---src-pages-combo-apps-ebooks-cursos-tsx" */),
  "component---src-pages-combo-apps-tsx": () => import("./../../../src/pages/combo-apps.tsx" /* webpackChunkName: "component---src-pages-combo-apps-tsx" */),
  "component---src-pages-combo-cursos-tsx": () => import("./../../../src/pages/combo-cursos.tsx" /* webpackChunkName: "component---src-pages-combo-cursos-tsx" */),
  "component---src-pages-combo-ebooks-app-tsx": () => import("./../../../src/pages/combo-ebooks-app.tsx" /* webpackChunkName: "component---src-pages-combo-ebooks-app-tsx" */),
  "component---src-pages-combo-ebooks-produtividade-habitos-metas-tsx": () => import("./../../../src/pages/combo-ebooks-produtividade-habitos-metas.tsx" /* webpackChunkName: "component---src-pages-combo-ebooks-produtividade-habitos-metas-tsx" */),
  "component---src-pages-combos-tsx": () => import("./../../../src/pages/combos.tsx" /* webpackChunkName: "component---src-pages-combos-tsx" */),
  "component---src-pages-curso-domine-a-arte-da-produtividade-tsx": () => import("./../../../src/pages/curso-domine-a-arte-da-produtividade.tsx" /* webpackChunkName: "component---src-pages-curso-domine-a-arte-da-produtividade-tsx" */),
  "component---src-pages-curso-domine-seus-habitos-tsx": () => import("./../../../src/pages/curso-domine-seus-habitos.tsx" /* webpackChunkName: "component---src-pages-curso-domine-seus-habitos-tsx" */),
  "component---src-pages-cursos-index-tsx": () => import("./../../../src/pages/cursos/index.tsx" /* webpackChunkName: "component---src-pages-cursos-index-tsx" */),
  "component---src-pages-ebooks-guia-completo-habitos-index-tsx": () => import("./../../../src/pages/ebooks/guia-completo-habitos/index.tsx" /* webpackChunkName: "component---src-pages-ebooks-guia-completo-habitos-index-tsx" */),
  "component---src-pages-ebooks-guia-definitivo-para-a-produtividade-index-tsx": () => import("./../../../src/pages/ebooks/guia-definitivo-para-a-produtividade/index.tsx" /* webpackChunkName: "component---src-pages-ebooks-guia-definitivo-para-a-produtividade-index-tsx" */),
  "component---src-pages-ebooks-index-tsx": () => import("./../../../src/pages/ebooks/index.tsx" /* webpackChunkName: "component---src-pages-ebooks-index-tsx" */),
  "component---src-pages-ebooks-metas-e-planejamento-index-tsx": () => import("./../../../src/pages/ebooks/metas-e-planejamento/index.tsx" /* webpackChunkName: "component---src-pages-ebooks-metas-e-planejamento-index-tsx" */),
  "component---src-pages-extensao-tsx": () => import("./../../../src/pages/extensao.tsx" /* webpackChunkName: "component---src-pages-extensao-tsx" */),
  "component---src-pages-guia-completo-habitos-e-metas-tsx": () => import("./../../../src/pages/guia-completo-habitos-e-metas.tsx" /* webpackChunkName: "component---src-pages-guia-completo-habitos-e-metas-tsx" */),
  "component---src-pages-guia-completo-habitos-tsx": () => import("./../../../src/pages/guia-completo-habitos.tsx" /* webpackChunkName: "component---src-pages-guia-completo-habitos-tsx" */),
  "component---src-pages-guia-definitivo-para-a-produtividade-tsx": () => import("./../../../src/pages/guia-definitivo-para-a-produtividade.tsx" /* webpackChunkName: "component---src-pages-guia-definitivo-para-a-produtividade-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-metas-e-planejamento-tsx": () => import("./../../../src/pages/metas-e-planejamento.tsx" /* webpackChunkName: "component---src-pages-metas-e-planejamento-tsx" */),
  "component---src-pages-newsletter-index-tsx": () => import("./../../../src/pages/newsletter/index.tsx" /* webpackChunkName: "component---src-pages-newsletter-index-tsx" */),
  "component---src-pages-newsletter-subscribed-tsx": () => import("./../../../src/pages/newsletter/subscribed.tsx" /* webpackChunkName: "component---src-pages-newsletter-subscribed-tsx" */),
  "component---src-pages-newsletter-unsubscribe-tsx": () => import("./../../../src/pages/newsletter/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-newsletter-unsubscribe-tsx" */),
  "component---src-pages-oferta-relampago-tsx": () => import("./../../../src/pages/oferta-relampago.tsx" /* webpackChunkName: "component---src-pages-oferta-relampago-tsx" */),
  "component---src-pages-plataforma-web-tsx": () => import("./../../../src/pages/plataforma-web.tsx" /* webpackChunkName: "component---src-pages-plataforma-web-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-ranking-tsx": () => import("./../../../src/pages/ranking.tsx" /* webpackChunkName: "component---src-pages-ranking-tsx" */),
  "component---src-pages-termos-de-uso-tsx": () => import("./../../../src/pages/termos-de-uso.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-tsx" */),
  "component---src-pages-termos-tsx": () => import("./../../../src/pages/termos.tsx" /* webpackChunkName: "component---src-pages-termos-tsx" */),
  "component---src-pages-unsubscribe-email-tsx": () => import("./../../../src/pages/unsubscribe-email.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-email-tsx" */)
}

